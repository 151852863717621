window.__tnt || (window.__tnt = {});

window.addEventListener('tnt-dmp-audience-extracted-lotame', function(e) {
    tntDmpReactiveLotame(e.detail.userAudience);
});
    
window.addEventListener('tnt-dmp-audience-extracted-segment', function(e) {
    tntDmpReactiveSegment(e.detail.userAudience);
});

function tntDmpReactiveLotame(audiences) {
    // Get service cookie
    var services = Cookies.get('tncms-services');
    
    // Scrub audiences
    for(var i = 0; i < audiences.length; i++) {
       audiences[i] = audiences[i].replace('keyword:', '');
    }
    
    // Check reactive blocks
    document.querySelectorAll('[data-dmp-show-lotame], [data-dmp-hide-lotame]').forEach(function(item) {
        // Service consideration
        var subscriberHide = false,
            subscriberShow = false;
        
        if (item.dataset.dmpHideLotame && item.dataset.dmpHideLotame.includes('subscriber') && services) subscriberHide = true;
        if (item.dataset.dmpShowLotame && item.dataset.dmpShowLotame.includes('subscriber') && services) subscriberShow = true;
        
        // Run audience check
        if (subscriberShow) {
            item.classList.remove('hidden');
        } else if (__tnt.compareArray(item.dataset.dmpShowLotame, audiences) === true
                || __tnt.compareArray(item.dataset.dmpHideLotame, audiences) === false) {
            // Reveal if show and not subscriber hide
            if (!subscriberHide) item.classList.remove('hidden');
        }
    });
}

function tntDmpReactiveSegment(audiences) {
    // Get service cookie
    var services = Cookies.get('tncms-services');
    
    // Check reactive blocks
    document.querySelectorAll('[data-dmp-show-segment], [data-dmp-hide-segment]').forEach(function(item) {
        // Service consideration
        var subscriberHide = false,
            subscriberShow = false;
        
        if (item.dataset.dmpHideSegment && item.dataset.dmpHideSegment.includes('subscriber') && services) subscriberHide = true;
        if (item.dataset.dmpShowSegment && item.dataset.dmpShowSegment.includes('subscriber') && services) subscriberHide = true;
        
        // Run audience check
        if (subscriberShow) {
            item.classList.remove('hidden');
        } else if (__tnt.compareArray(item.dataset.dmpShowSegment, audiences) === true
                || __tnt.compareArray(item.dataset.dmpHideSegment, audiences) === false) {
            // Reveal if show and not subscriber hide
            if (!subscriberHide) item.classList.remove('hidden');
        }
    });
}